import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: any) => ({
  heroBox: {
    paddingBottom: '5rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '2rem',
    },
  },
  heroText: {
    marginBottom: '3rem !important',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem !important',
    },
  },
}))

export default useStyles
