import { FC } from 'react'
import HostProfile from 'components/HostProfile'
import Layout from 'components/Layout'

const Profile: FC = () => {
  return (
    <Layout>
      <HostProfile />
    </Layout>
  )
}

export default Profile
