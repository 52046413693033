import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { useLocale } from 'containers'

const HomeContent: FC = () => {
  const subDomain = window.location.hostname.split('.')[0].toLowerCase()
  // const subDomain = 'bodenseekreis'
  const { messages } = useLocale()

  return (
    <Box
      component={'section'}
      sx={{ padding: '2rem', maxWidth: '2000px', margin: 'auto' }}
    >
      {subDomain === 'bodenseekreis' && (
        <Typography>{messages['home.intro.bodenseekreis']}</Typography>
      )}
      <Typography
        variant={'h4'}
        color='textPrimary'
        style={{ marginBottom: '10px', marginTop: '10px' }}
      >
        {messages['intro-home.howto.heading']}
      </Typography>
      <ul>
        <li>
          <Typography
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {messages['intro-home.howto.step1']}
          </Typography>
        </li>
        <li>
          <Typography
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {messages['intro-home.howto.step2']}
          </Typography>
        </li>
        <li>
          <Typography
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {messages['intro-home.howto.step3']}
          </Typography>
        </li>
        <li>
          <Typography
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {messages['intro-home.howto.step4']}
          </Typography>
        </li>
        <li>
          <Typography
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {messages['intro-home.howto.step5']}
          </Typography>
        </li>
        <li>
          <Typography color='textPrimary' style={{ marginTop: '10px' }}>
            {messages['intro-home.howto.step6']}
          </Typography>
        </li>
        <li>
          <Typography color='textPrimary' style={{ marginTop: '10px' }}>
            {messages['intro-home.howto.step7']}
          </Typography>
        </li>
        <li>
          <Typography color='textPrimary' style={{ marginTop: '10px' }}>
            {messages['intro-home.howto.step8']}
          </Typography>
        </li>
      </ul>
    </Box>
  )
}

export default HomeContent
