import { FC } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  Home,
  Imprint,
  Offering,
  Offers,
  Profile,
  OfferDetails,
  OfferMessages,
} from 'routes'
import { AlertProvider, AuthProvider, LocaleProvider } from 'containers'
import theme from './MaterialTheme'

const token = window.sessionStorage.getItem('token')

const App: FC = () => {
  const locale = ['uk', 'ua'].includes(navigator.language) ? 'uk' : 'de'

  return (
    <ThemeProvider theme={theme}>
      <LocaleProvider initialValue={locale}>
        <AlertProvider initialValue={undefined}>
          <AuthProvider initialValue={token}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <BrowserRouter>
                <CssBaseline />
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/impressum' element={<Imprint />} />
                  <Route path='/offer' element={<Offering />} />
                  <Route path='/user-offers' element={<Offers />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/offer-details' element={<OfferDetails />} />
                  <Route path='/offer-messages' element={<OfferMessages />} />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </AuthProvider>
        </AlertProvider>
      </LocaleProvider>
    </ThemeProvider>
  )
}

export default App
