import React, { FC } from 'react'
import { Box } from '@mui/material'
import useStyles from './styles'

const Jumbotron: FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.heroImage} component='section'>
      {children}
    </Box>
  )
}

export default Jumbotron
