import { Fragment, FC, useState, MouseEvent } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import CommentIcon from '@mui/icons-material/Comment'
import ListItemIcon from '@mui/material/ListItemIcon'
import PersonIcon from '@mui/icons-material/Person'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useLocale } from 'containers'
import { useNavigate } from 'react-router-dom'

interface IContactList {
  contacts: string[]
  offerId: string
  selected?: string
}

const formatContact = (contact: string): string => {
  return `${contact.slice(0, contact.indexOf('-'))}...`
}

const ContactList: FC<IContactList> = ({ contacts, offerId, selected }) => {
  const { messages } = useLocale()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {matches ? (
        <List>
          {contacts.map((c) => (
              <ListItem
                disablePadding
                key={c}
                secondaryAction={
                  <IconButton edge='end' aria-label='comments'>
                    <CommentIcon />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() =>
                    navigate(`/offer-messages?contactId=${c}&offerId=${offerId}`)
                  }
                  selected={selected === c}
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>

                  <ListItemText primary={formatContact(c)} />
                </ListItemButton>
              </ListItem>
          ))}
        </List>
      ) : (
        <Fragment>
          <Button variant='contained' onClick={handleClick}>
            {messages['contact-list.button.contacts']}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {contacts.map((c) => (
              <MenuItem
                key={c}
                onClick={() => {
                  navigate(`/offer-messages?contactId=${c}&offerId=${offerId}`)

                  setAnchorEl(null)
                }}
              >
                {formatContact(c)}
              </MenuItem>
            ))}
          </Menu>
        </Fragment>
      )}
    </>
  )
}

export default ContactList
