import { FC, useState } from 'react'
import { TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useRequest } from 'helpers'
import { useForm, Controller } from 'react-hook-form'
import { useAlert, useLocale } from 'containers'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const Login: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: { email: '' },
  })
  const { request } = useRequest()
  const { messages } = useLocale()
  const { setAlert } = useAlert()

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (data: any) => {
    const { email } = data
    setLoading(true)
    try {
      const res = await request('auth', 'post', { email })
      if (res.ok) {
        setAlert({
          type: 'success',
          message: `Wir haben einen Link an ${email} geschickt, bitte klicken Sie auf den Link, um sich einzuloggen!`,
        })
        reset()
        onClose()
      } else {
        setAlert({
          type: 'error',
          message: messages['header.alert.error'],
        })
      }
      setLoading(false)
    } catch (error) {
      setAlert({
        type: 'error',
        message: messages['header.alert.error'],
      })
      setLoading(false)
    }
  }
  return (
    <Dialog open onClose={onClose} fullWidth>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='email'
            rules={{
              required: messages['offer-form.warning.email'],
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: messages['offer-form.warning.email.pattern'],
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                error={fieldState.invalid}
                margin='none'
                size='small'
                onChange={field.onChange}
                placeholder='E-Mail'
                variant='outlined'
                sx={{ backgroundColor: '#fff' }}
                value={field.value}
                helperText={fieldState.error?.message}
                fullWidth
              />
            )}
          />
          <DialogActions>
            <LoadingButton
              type='submit'
              variant='contained'
              size='small'
              loading={loading}
            >
              {messages['header.button.sendLogin']}
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default Login
