import { FC, forwardRef, useEffect } from 'react'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { Container, Snackbar, Backdrop, CircularProgress } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useAlert, useAuth } from 'containers'
import { useQueryParams, useRequest } from 'helpers'
import { useNavigate } from 'react-router-dom'
import { useLocale, useOffers } from 'containers'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Layout: FC = ({ children }) => {
  const { messages } = useLocale()
  const { alert, setAlert } = useAlert()
  const navigate = useNavigate()
  const params = useQueryParams()
  const token = params.getOne('bearer')
  const messageId = params.getOne('message')
  const offerId = params.getOne('offerId')

  const { setToken, userId } = useAuth()
  const { request } = useRequest()
  const { loading } = useOffers()

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        await request('auth', 'post')
        setAlert({
          type: 'info',
          message: messages['layout.alert.info'],
        })
        if (messageId && offerId) {
          const offerMessages = await request(`contacts/${offerId}`, 'get')
          const { messages } = await offerMessages.json()
          const contactId = messages.find((m: any) => m.id === messageId).issuer
            .id
          navigate(`/offer-messages?contactId=${contactId}&offerId=${offerId}`)
        } else {
          navigate('/')
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (token) {
      setToken(token)
      authenticateUser()
    }
  }, [
    token,
    userId,
    messages,
    navigate,
    setAlert,
    setToken,
    request,
    messageId,
    offerId,
  ])

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        minHeight: '100vh',
      }}
    >
      <Header />
      <main style={{ flex: 'auto' }}>{children}</main>
      <Footer />

      {alert && (
        <Snackbar open autoHideDuration={6000} onClose={() => setAlert()}>
          <Alert
            onClose={() => setAlert()}
            severity={alert.type}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </Container>
  )
}

export default Layout
