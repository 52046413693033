import React, { FC } from 'react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'

import Layout from 'components/Layout'

const Imprint: FC = () => {
  const subDomain = window.location.hostname.split('.')[0].toLowerCase()
  return (
    <Layout>
      <Grid
        container
        direction='row'
        justifyContent='center'
        sx={{ marginTop: '100px' }}
      >
        <Grid item xs={11}>
          <Typography
            variant={'h4'}
            color='textPrimary'
            style={{ marginBottom: '20px', marginTop: '20px' }}
          >
            Impressum und Datenschutzerklärung
          </Typography>
          <Typography
            variant={'h6'}
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            Impressum
          </Typography>
          <Typography
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            Angaben gemäß § 5 TMG
          </Typography>
          <Typography
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            LambdaQoppa Enterprise GmbH
            <br />
            Niederbarnimstr. 5<br />
            10247 Berlin
            <br />
            <br />
            Vertreten durch:
            <br />
            Tom Lawson und Benedikt Frings
            <br />
            <br />
            Kontakt:
            <br />
            Telefon: +49-30-5884-9039
            <br />
            E-Mail: info@lq-enterprise.com
            <br />
            <br />
            Registereintrag:
            <br />
            Eintragung im Registergericht: Berlin, Amtsgericht Charlottenburg
            <br />
            Registernummer: HRB 177476 B<br />
            <br />
            Umsatzsteuer-ID:
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
            DE306831982
            <br />
            <br />
            Inhaltlich Verantwortlicher nach § 55 Abs. 2 RStV:
            <br />
            LQ Enterprise GmbH
            <br />
            <br />
          </Typography>
          {subDomain === 'bodenseekreis' && (
            <>
              <Typography variant='h4'>Auftraggeber</Typography>
              <Typography>
                Die Plattform wird im Auftrag der Auftraggeber der LambdaQoppa
                Enterprise GmbH betrieben:
                <br />
                <br />
              </Typography>
              <Typography>
                Landratsamt Bodenseekreis, vertreten durch den <br />
                Landrat <br />
                Albrechtstraße 77
                <br />
                88045 Friedrichshafen <br />
                info@bodenseekreis.de
              </Typography>
              <br />
              <br />
              <Typography variant='h4'>
                Der Schutz Ihrer Daten hat oberste Priorität.
              </Typography>
              <Typography>
                Ihre Daten sind sicher und nicht einsehbar. <br />
                Mit dem Speichern Ihrer personenbezogenen Daten auf dieser
                Plattform erklären Sie, dass Sie mit der Verarbeitung Ihrer
                Daten einverstanden sind. <br />
                Auf die Datenschutzinformationen auf{' '}
                <a
                  href='https://www.bodenseekreis.de/datenschutz'
                  target={'_blank'}
                  rel='noreferrer'
                >
                  www.bodenseekreis.de/datenschutz
                </a>{' '}
                wird hingewiesen. <br />
                Die Kommunikation läuft über diese Plattform und Ihren
                persönlichen Zugang. <br />
                Bei Kontaktaufnahme werden Sie per E-Mail informiert und können
                die Nachricht über Ihren Zugangslink einsehen. <br />
                Ihre E-Mail-Adresse oder andere persönliche Informationen sind
                zu keinem Zeitpunkt einsehbar. <br />
                Sie entscheiden selbst, wann Sie Kontaktinformationen mit dem
                Gegenüber in einer Nachricht austauschen. <br /> <br />
              </Typography>
            </>
          )}

          <Typography variant='h4'>Nutzungsbedingungen Hospodar</Typography>
          <Typography>
            Bitte lesen Sie diese Nutzungsbedingungen sorgfältig, bevor Sie die
            Seite Hospodar betreten.
            <br />
            Um die Seite Hospodar nutzen zu können, muss sich der Nutzer mit
            diesen Nutzungsbedingungen einverstanden erklären.
            <br />
            Durch das Betreten und die Nutzung der Seite Hospodar akzeptieren
            Sie die Nutzungsbedingungen automatisch und stimmen diesen zu.
            <br />
            <br />
            Die Seite Hospodar, die Ihnen kostenfrei zur Verfügung gestellt
            wird, ist eine World Wide Web Site im Internet, die es Nutzern
            erlaubt, Anzeigen selbst zu schalten und/oder Anzeigen anzusehen,
            die von anderen Nutzern geschaltet wurden oder mit diesen Kontakt
            aufzunehmen.
          </Typography>
          <Typography>
            Zur Vermittlung werden Ihre Telefonnummer und Ihre E-Mail-Adresse
            gespeichert - aber nicht ohne Ihrer explizite Zustimmung
            veröffentlicht.
          </Typography>
          <br />
          <br />
          <Typography variant='h6'>
            Rechte und Verantwortlichkeiten der Nutzer
          </Typography>
          <Typography>
            Zugang zur Seite Hospodar: Sie sind verantwortlich für die
            Bereitstellung von Hardware, Software, Telefon oder andere
            Kommunikationsausstattung, um eine Verbindung zum Internet
            herstellen und die Sperrmüllbörse betreten zu können. Sie tragen
            auch selbst alle Internet- oder Telefongebühren oder weitere
            Gebühren, die anfallen, um ins Internet und zur Seite Hospodar zu
            gelangen.
          </Typography>
          <Typography>
            Nutzerverhalten: Sie stimmen zu, die Seite Hospodar nur für legale
            Zwecke zu betreten und zu nutzen. Sie allein tragen die
            Verantwortung für die Kenntnis und den Einklang mit allen Gesetzen
            und Verordnungen, die
            <br />
            (a) die Nutzung der Seite Hospodar,
            <br />
            (b) die Nutzung jedweder Netzwerke oder Service in Verbindung zur
            Seite Hospodar und
            <br />
            (c) die Kommunikationsmittel betreffen, mit denen Sie Ihr Modem,
            Ihren Computer oder andere Ausrüstung mit der <br />
            Seite Hospodar verbinden. Durch das Betreten der Seite Hospodar,
            stimmen Sie zu, daß Sie
            <br />
            (1) keinen anderen Nutzer bei der Nutzung der Seite Hospodar
            behindern;
            <br />
            (2) keine ungesetzlichen, bedrohenden, mißbräuchlichen, obszönen
            oder pornographischen Daten jeder Art übertragen oder aufgeben.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Copyrights</Typography>
          <Typography>
            Inhalte: (c)Copyright LambdaQoppa Enterprise GmbH. Alle Rechte
            vorbehalten. Die Reproduktion oder Modifikation ganz oder teilweise
            ohne schriftliche Genehmigung durch LambdaQoppa Enterprise GmbH ist
            untersagt.
            <br />
          </Typography>
          <Typography>
            Anzeigen: Anzeigen von professionellen Anbietern sind nicht erlaubt.
            Die Seite Hospodar steht nur Privatpersonen zur Verfügung.
            <br />
          </Typography>
          <Typography>
            Die Seite Hospodar behält sich vor, Anzeigen, die den oben genannten
            Bedingungen widersprechen oder den Werten der LambdaQoppa Enterprise
            GmbH oder aus sonstigen Gründen, kommentarlos zu löschen.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Haftungsausschluss</Typography>
          <Typography>
            Ansprüche: Ansprüche, die sich aus der Nutzung der Seite Hospodar
            ergeben könnten sind ausgeschlossen. <br />
            LambdaQoppa Enterprise GmbH hat keinen Einfluß auf die
            Informationen, die von Nutzern veröffentlich werden und ist an
            möglichen Verträgen und Ansprüchen zwischen Inserenten und Dritten
            nicht beteiligt.
            <br />
            Haftung für Informationen: LambdaQoppa Enterprise GmbH übernimmt
            keine Gewähr für die Richtigkeit und Vollständigkeit der auf diesen
            Seiten befindlichen Informationen.
            <br />
            <br />
            Links: Hinweis: LambdaQoppa Enterprise GmbH ist für Inhalte externer
            Seiten, auf die im Rahmen dieses Onlineangebotes verwiesen wird,
            nicht verantwortlich und lehnt jegliche Haftung für deren Inhalte
            ab.
            <br />
            <br />
          </Typography>
          <Typography variant='h4'>Datenschutzerklärung Hospodar</Typography>
          <br />
          <br />
          <Typography>
            LambdaQoppa Enterprise GmbH nimmt den Schutz persönlicher Daten sehr
            ernst und hält sich an die geltenden Gesetze und Vorschriften zum
            Datenschutz, insbesondere die Datenschutzgrundverordnung (DSGVO) und
            das Telekommunikation-Telemedien-Datenschutz-Gesetz (TTDSG). Die
            folgenden Erläuterungen geben einen Überblick darüber, wie dieser
            Schutz sichergestellt wird und welche Daten zu welchem Zweck
            verarbeitet werden.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Verantwortliche Stelle</Typography>
          <Typography>
            Verantwortlicher nach Art. 4 DSGVO und zugleich Diensteanbieter i.
            S. d. Telemediengesetzes ist LambdaQoppa Enterprise GmbH.
            Vertretungsberechtigter ist Herr Tom Lawson und Benedikt Frings.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Nutzungsdaten</Typography>
          <Typography>
            Bei jedem Zugriff auf diese Webseite und bei jedem Abruf einer Datei
            werden über diesen Vorgang automatisch allgemeine Daten in einer
            Protokolldatei gespeichert. Die Speicherung dient ausschließlich
            systembezogenen und statistischen Zwecken, sowie in Ausnahmefällen
            zur Anzeige von Straftaten. Eine Weitergabe der Daten an Dritte oder
            eine sonstige Auswertung findet nicht statt, es sei denn, es besteht
            eine gesetzliche Verpflichtung dazu.
            <br />
            <br />
          </Typography>
          <Typography>
            Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert:
            Name der abgerufenen Datei Datum und Uhrzeit des Abrufs übertragene
            Datenmenge Meldung, ob der Abruf erfolgreich war Beschreibung des
            Typs des verwendeten Webbrowsers verwendetes Betriebssystem die
            zuvor besuchte Seite Provider IP-Adresse
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Personenbezogene Daten</Typography>
          <Typography>
            Personenbezogene Daten werden von uns nur dann verarbeitet, wenn uns
            dies gesetzlich gestattet ist, oder Sie uns Ihre Einwilligung
            erteilt haben.
            <br />
            Im Einzelnen:
            <br />
            <br />
            a) Kontakt Wenn Sie mit uns in Kontakt treten, willigen Sie in die
            Verarbeitung Ihrer Daten zur Bearbeitung Ihrer Anfrage ein sowie für
            den Fall, dass eine weitere Korrespondenz stattfinden soll. Bitte
            verwenden Sie zur Übermittlung Ihrer Daten einen sicheren
            Übermittlungsweg.
            <br />
            <br />
            b) Unterstützungsangeboten, Gastgeber-Anzeigen und
            Interessenbekundundungen von Suchenden einstellen und verwalten Beim
            Einstellen von Gastgeber-Anzeigen und Interessenbekundungen durch
            Suchende werden Daten zum angebotenen Wohnraum und persönliche Daten
            wie Strasse,
            <br />
            Hausnummer, <br />
            Postleitzahl, <br />
            Ort, <br />
            Telefonnummer und <br />
            E-Mail Adresse abgefragt.
            <br />
            <br />
            Sie können diese Angaben online in das dafür vorgesehene
            Abfrageformular eintragen. Nachdem Sie diese Daten abgesendet haben,
            erhalten Sie eine E-Mail an die von Ihnen angegebene Mailadresse, in
            der Sie zur Verifizierung einen Bestätigungslink anklicken müssen.
            Ihre Daten werden hier nur zum Zwecke Angebotsverwaltung verarbeitet
            und in einer Datenbank gespeichert.
            <br />
            Rechtsgrundlage der Datenverarbeitung ist mit Anklicken des
            Bestätigungslinks im Abfrageformular Ihre Einwilligung und damit
            Art. 6 Abs. 1 Buchstabe a) DSGVO. <br />
            <br />
            Zweck der Speicherung und Datenverarbeitung ist die Vermittlung von
            Unterstützungsangeboten für Vertriebene aus der Ukraine. Die Daten
            werden, sofern erforderlich, für die Dauer der Hilfsangebote
            gespeichert.
            <br />
            <br />
            Sie können sich jederzeit die Angebote bzw. Interessenbekundungen zu
            Angeboten wieder löschen, sich nach Löschung des letzten Eintrags
            automatisch abmelden und damit der weiteren Verwendung Ihrer Daten
            widersprechen. Ihre Daten werden dann aus der Datenbank gelöscht.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Cookies</Typography>
          <Typography>
            In einigen Bereichen dieser Webseite werden sogenannte Cookies
            eingesetzt. Durch solche Dateielemente kann Ihr Computer als
            technische Einheit während Ihres Besuchs auf dieser Webseite
            identifiziert werden, um Ihnen die Verwendung unseres Angebotes -
            auch bei Wiederholungsbesuchen - zu erleichtern.
            <br />
            Sie haben in der Regel die Möglichkeit, Ihren Internetbrowser so
            einzustellen, dass Sie über das Auftreten von Cookies informiert
            werden, so dass Sie diese zulassen oder ausschließen,
            beziehungsweise bereits vorhandene Cookies löschen können.
            <br />
            Bitte verwenden Sie die Hilfefunktion Ihres Internetbrowsers, um
            Informationen zu der Änderung dieser Einstellungen zu erlangen.
            <br />
            Wir weisen darauf hin, dass einzelne Funktionen unserer Webseite
            möglicherweise nicht funktionieren, wenn Sie die Verwendung von
            Cookies deaktiviert haben. <br />
            Cookies erlauben nicht, dass ein Server private Daten von Ihrem
            Computer oder die von einem anderen Server abgelegten Daten lesen
            kann. Sie richten auf Ihrem Rechner keinen Schaden an und enthalten
            keine Viren.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>
            Verwendung des Analyse-Tools Application Insights
          </Typography>
          <Typography>
            Unsere Webseite benutzt Application Insights, eine Software zur
            Analyse der Besucherzugriffe auf die eigene Webseite.
            <br />
            Die Software läuft direkt auf unserem Webserver. Bei Application
            Insights handelt es sich weder um ein Tracking-Tool noch um Cookies,
            die auf Ihrem Computer gespeichert werden. Die Software wird zur
            Auswertung von Log-Dateien verwendet, die unser Webserver auf Basis
            von Besucheranfragen erstellt.
            <br />
            Wir stützen den Einsatz des Analyse-Tools auf Art. 6 Abs. 1
            Buchstabe f DSGVO, d. h. die Verarbeitung erfolgt zur Analyse von
            Besucheranfragen auf unserer Webseite und ist daher zur Wahrung
            unserer berechtigten Interessen erforderlich.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>
            Verwendung von Social Media Plugins
          </Typography>
          <Typography>
            Diese Webseite enthält keine sogenannten Social Media Plugins.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Verwendung von Google Maps</Typography>
          <Typography>
            Auf unserer Webseite benutzen wir zukünftig Google Maps, um für Sie
            die Lokation von Gastgeber-Anzeigen darzustellen und Ihnen die
            Planung der Anfahrt zu vereinfachen. Hierbei greifen wir auf Dienste
            der Google Inc. 1600 Amphitheatre Parkway, Mountain View, CA 94043,
            USA zurück.
            <br />
            Dazu lädt beim Aufrufen unserer Seite Ihr Browser die benötigten
            Informationen vom Google-Server in den USA in ihren Browsercache.
            <br />
            Dies ist notwendig damit auch Ihr Browser eine optisch verbesserte
            Darstellung unserer Texte anzeigen kann, bzw. die Karte auf unserer
            Webseite dargestellt wird. Hierdurch wird an den Google-Server
            übermittelt, welche unserer Internetseiten Sie besucht haben. Auch
            wird Ihre IP-Adresse von Google gespeichert.
            <br />
            Weitergehende Informationen zu Google Maps finden sie unter
            www.google.com/intl/de_de/help/terms_maps.html Allgemeine
            Informationen zum Thema Datenschutz bei Google finden sie unter
            https://policies.google.com/privacy
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Dauer der Speicherung</Typography>
          <Typography>
            Wir speichern Ihre Daten so lange, wie es für eine abschließende
            Bearbeitung Ihres Anliegens notwendig ist.
            <br />
            Ausgenommen hiervon sind Daten, für die gesetzliche oder anderweitig
            vorgeschriebene Aufbewahrungspflichten bestehen; diese werden für
            die Dauer der jeweiligen Aufbewahrungsfrist gespeichert und im
            Anschluss ordnungsgemäß gelöscht.
            <br />
            Die über das Analyse-Tool Application Insights erhobenen
            Statistikdaten werden ein Jahr lang gespeichert und aufbewahrt.
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Betroffenenrechte</Typography>
          <Typography>
            a) Auskunftsrecht
            <br />
            Sie haben das Recht, Auskunft über die Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
            <br />
            <br />
            b) Berichtigung/Löschung/Einschränkung der Verarbeitung Sie haben
            das Recht, von uns zu verlangen, dass
            <br />
            Ihre Person betreffende unrichtige personenbezogene Daten
            unverzüglich berichtigt werden (Recht auf Berichtigung);
            <br />
            Ihre Person betreffende personenbezogene Daten unverzüglich gelöscht
            werden (Recht auf Löschung) und die Verarbeitung eingeschränkt wird
            (Recht auf Einschränken der Verarbeitung).
            <br />
            <br />
            c) Recht auf Datenübertragbarkeit
            <br />
            Sie haben das Recht, Ihre Person betreffende personenbezogene Daten,
            die Sie uns bereitgestellt haben, in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten und diese Daten einem
            anderen Verantwortlichen zu übermitteln.
            <br />
            <br />
            d) Widerrufsrecht
            <br />
            Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen.
            Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
            nicht berührt.
            <br />
            <br />
            e) Widerspruchsrecht
            <br />
            Ist die Verarbeitung Sie betreffender personenbezogener Daten für
            die Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
            (Art. 6 Abs. 1 Buchst. e) DSGVO) oder zur Wahrung unserer
            berechtigten Interessen (Art. 6 Abs. 1 Buchst. f) DSGVO)
            erforderlich, steht Ihnen ein Widerspruchsrecht zu.
            <br />
            <br />
            f) Beschwerderecht
            <br />
            Sind Sie der Ansicht, dass die Verarbeitung der Sie betreffenden
            personenbezogenen Daten gegen die Datenschutzgrundverordnung
            verstößt, haben Sie unbeschadet anderweitiger Rechtsbehelfe das
            Recht auf Beschwerde bei einer Aufsichtsbehörde.
            <br />
            <br />
            Die Aufsichtsbehörde erreichen Sie unter:
            <br />
            <br />
            Datenschutzbeauftragter LambdaQoppa Enterprise GmbH
            <br />
            Postanschrift:
            <br />
            Niederbarnimstrasse 5<br />
            10247 Berlin <br />
            <br />
            datenschutz@lq-enterprise.com
            <br />
            <br />
          </Typography>
          <Typography variant='h6'>Sicherheit</Typography>
          <Typography>
            Für die Sicherheit Ihrer Daten verwendet LambdaQoppa Enterprise GmbH
            Programme und Systeme, die dem Stand der Technik entsprechen und
            fortlaufend aktualisiert werden. Während des Online-Anfrageprozesses
            sind Ihre Angaben mit einer SSL-Verschlüsselung gesichert. Für eine
            sichere Speicherung Ihrer Daten werden unsere Systeme durch
            Firewalls geschützt, die unberechtigte Zugriffe von außen
            verhindern.
            <br />
            Die Nutzung der Internetseiten LambdaQoppa Enterprise GmbH ist in
            der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
            den Webseiten personenbezogene Daten erhoben werden, so erfolgt dies
            stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
            ausdrückliche Zustimmung nicht an Dritte weitergegeben.
            <br />
            Es wird darauf hingewiesen, dass die Datenübertragung im Internet
            (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
            kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte
            ist nicht möglich.
            <br />
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
            von Werbeinformationen, etwa durch Spam-Mails, vor.
            <br />
            <br />
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Imprint
