import { FC } from 'react'
import MuiDatePicker from '@mui/lab/DatePicker'
import { ControllerRenderProps, ControllerFieldState } from 'react-hook-form'
import { TextField } from '@mui/material'
import { useLocale } from 'containers'
// import
interface IDatePicker {
  field: ControllerRenderProps
  fieldState: ControllerFieldState
  minDate?: string | Date | null
  maxDate?: string | Date | null
}

const DatePicker: FC<IDatePicker> = ({
  field,
  fieldState,
  minDate,
  maxDate,
}) => {
  const { messages } = useLocale()
  return (
    <MuiDatePicker
      mask='__.__.____'
      label={messages[`offer-form.${field.name}`]}
      value={field.value}
      onChange={field.onChange}
      minDate={minDate}
      maxDate={maxDate}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          fullWidth
        />
      )}
      inputFormat='dd.MM.yyyy'
    />
  )
}

export default DatePicker
