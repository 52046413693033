import { FC } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useLocale } from 'containers'
import ReactCountryFlag from 'react-country-flag'

const SelectValue: FC<{ value: any }> = ({ value }) => {
  const languageCode = value === 'de' ? 'DE' : 'UA'
  return <ReactCountryFlag countryCode={languageCode} svg />
}

const SelectLocale: FC = () => {
  const { locale, setLocale } = useLocale()
  const handleChange = (event: SelectChangeEvent) => {
    setLocale(event.target.value)
  }
  return (
    <Select
      value={locale}
      onChange={handleChange}
      size='small'
      variant='standard'
      renderValue={(value: any) => <SelectValue value={value} />}
    >
      <MenuItem value={'de'}>
        <ReactCountryFlag countryCode='DE' svg />
      </MenuItem>
      <MenuItem value={'uk'}>
        <ReactCountryFlag countryCode='UA' svg />
      </MenuItem>
    </Select>
  )
}

export default SelectLocale
