import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    // background: {
    //   paper: '#85A9C3',
    //   default: '#85A9C3',
    // },
    primary: {
      main: '#85A9C3',
    },
    secondary: {
      main: '#fff',
    },
    text: {
      primary: '#203864',
      secondary: '#000',
    },
  },
  typography: {
    fontSize: 20,
    fontFamily: '"Courier New", monospace',
    h1: {
      fontFamily: '"Arial Black", sans-serif',
      fontSize: 50,
      fontWeight: 700,
    },
    h4: {
      fontFamily: '"Arial Black", sans-serif',
      fontSize: 40,
      fontWeight: 500,
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
