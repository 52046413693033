import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Typography, Toolbar, Box, AppBar } from '@mui/material'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import logo from 'assets/logo.png'
import logoBodensee from 'assets/logoBodensee.png'
import { useAuth, useLocale } from 'containers'
import SelectLocale from 'components/SelectLocale'
import useStyles from './styles'
import Login from 'components/Login'
import HeaderMenu from 'components/HeaderMenu'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const Header: FC = () => {
  const subDomain = window.location.hostname.split('.')[0]
  const { messages } = useLocale()
  const navigate = useNavigate()
  const classes = useStyles()
  const { isValid } = useAuth()
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const [headerStyle, setHeaderStyle] = useState<any>(classes.headerBaseStyle)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const handleLogout = () => {
    window.sessionStorage.removeItem('token')
    navigate('/')
    window.location.reload()
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        window.pageYOffset > 10
          ? setHeaderStyle(classes.headerOnScrollStyle)
          : setHeaderStyle(classes.headerBaseStyle)
      )
    }
    return () => {
      setHeaderStyle(classes.headerBaseStyle)
    }
  }, [classes])

  return (
    <AppBar position='fixed' className={headerStyle}>
      <Toolbar>
        <Box component='div' sx={{ flexGrow: 1, marginTop: '10px' }}>
          <Link to={'/'}>
            {subDomain.toLowerCase() === 'bodenseekreis' ? (
              <img
                src={logoBodensee}
                alt='logo'
                className={classes.headerLogo}
              />
            ) : (
              <img src={logo} alt='logo' className={classes.headerLogo} />
            )}
          </Link>
        </Box>
        {showLogin && <Login onClose={() => setShowLogin(false)} />}

        <Box>
          <SelectLocale />
        </Box>
        {!!isValid ? (
          <>
            {matches ? (
              <>
                <Box sx={{ marginLeft: '10px' }}>
                  <Button
                    size='large'
                    variant='contained'
                    color='secondary'
                    onClick={() => navigate('/user-offers')}
                  >
                    {messages['header.button.offers']}
                  </Button>
                </Box>
                <Box sx={{ marginLeft: '10px' }}>
                  <Button
                    size='large'
                    variant='contained'
                    color='secondary'
                    onClick={handleLogout}
                  >
                    {messages['header.button.logout']}
                  </Button>
                </Box>
              </>
            ) : (
              <HeaderMenu />
            )}
          </>
        ) : (
          <Box sx={{ marginLeft: '10px' }}>
            <Button
              variant='contained'
              endIcon={<VpnKeyIcon />}
              color='secondary'
              onClick={() => setShowLogin(true)}
              sx={{
                borderWidth: '3px',
                fontWeight: 'bold',
              }}
            >
              <Typography fontWeight={900}>
                {messages['header.button.login']}
              </Typography>
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
