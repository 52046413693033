import { FC } from 'react'
import { format } from 'date-fns'
import { Box, Typography, Grid } from '@mui/material'
import masterData, { Types } from 'model'
import { useLocale } from 'containers'

const getMasterLabel = (value: number, masterType: string) => {
  return masterData[masterType].find(
    (option: any) => (option.value as number) === value
  ) as Types.SelectOptionType
}

interface ICardOffer {
  offer: Types.OfferType
}

const OfferContent: FC<ICardOffer> = ({ offer }) => {
  const { messages } = useLocale()

  return (
    <Grid container>
      <Grid item xs={12} lg={6} xl={6}>
        <Box>
          <Typography>
            {messages['offer-form.type']}:{' '}
            {messages[getMasterLabel(offer.type, 'type').label]}
          </Typography>
          <Typography>
            {messages['offer-form.numberOfBeds']}: {offer.numberOfBeds}
          </Typography>
          <Typography>
            {messages['offer-form.numberOfRooms']}: {offer.numberOfRooms}
          </Typography>
          <Typography>
            {messages['offer-form.startingDate']}:{' '}
            {offer.startingDate &&
              format(new Date(offer.startingDate), 'dd.MM.yyyy')}
          </Typography>
          <Typography>
            {messages['offer-form.endDate']}:{' '}
            {offer.endDate && format(new Date(offer.endDate), 'dd.MM.yyyy')}
          </Typography>
          {/* <Typography>
            {messages['offer-form.street']}: {offer.street}
          </Typography> */}
          <Typography>
            {messages['offer-form.zip']}: {offer.zip}
          </Typography>
          <Typography>
            {messages['offer-form.city']}: {offer.city}
          </Typography>
          <Typography>
            {messages['offer-form.floor']}: {offer.floor}
          </Typography>
          <Typography>
            {messages['offer-form.livingArea']}: {offer.livingArea}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6} xl={6}>
        <Box>
          <Typography component={'section'}>
            {messages['offer-form.equipment']}:
            <ul>
              {offer.equipment?.map((e: any, index: number) =>
                e.value !== 12 ? (
                  <li key={index}>
                    {messages[getMasterLabel(e, 'equipment')?.label]}
                  </li>
                ) : null
              )}
            </ul>
          </Typography>
          <Typography>
            {messages['offer-form.annotations']}: {offer.annotations}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default OfferContent
