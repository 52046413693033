import { FC } from 'react'
import OfferForm from 'components/OfferForm'
import Layout from 'components/Layout'
import { Box } from '@mui/material'
import Intro from 'components/IntroOffering'

const Offering: FC = () => {
  return (
    <Layout>
      <Box marginTop={'3em'} component={'section'}>
        <Intro />
      </Box>
      <Box
        marginTop={'3em'}
        component={'section'}
        sx={{
          p: '2rem',
        }}
      >
        <OfferForm />
      </Box>
    </Layout>
  )
}

export default Offering
