import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: any) => ({
  headerBaseStyle: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    paddingY: '1rem',
  },
  headerOnScrollStyle: {
    paddingY: '1rem',
    backgroundColor: 'primary',
    opacity: '50%',
    boxShadow: 'none !important',
  },
  headerLogo: {
    height: '3.5em',
    [theme.breakpoints.down('sm')]: {
      height: '2em',
    },
  },
}))

export default useStyles
