import { FC, createContext, useContext, useState, useMemo } from 'react'
import de from 'translations/translations.de.json'
import uk from 'translations/translations.uk.json'

interface ILocaleContext {
  locale: string
  messages: any
  setLocale(locale: string): void
}

const LocaleContext = createContext<ILocaleContext>({
  locale: 'de',
  messages: de,
  setLocale: (locale: string) => {},
})

const useLocale = () => useContext(LocaleContext)

interface ILocaleProviderProps {
  initialValue: string
}

const LocaleProvider: FC<ILocaleProviderProps> = ({
  children,
  initialValue,
}) => {
  const [locale, setLocale] = useState<string>(initialValue)

  const value = useMemo(() => {
    const translations: any = { de, uk }
    return {
      locale,
      messages: translations[locale],
      setLocale: (locale: string) => setLocale(locale),
    }
  }, [locale])
  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  )
}

export { LocaleProvider, useLocale }
