import { FC, ChangeEvent } from 'react'
import { ControllerRenderProps, ControllerFieldState } from 'react-hook-form'
import {
  FormControl,
  FormHelperText,
  FormGroup,
  Checkbox,
  FormLabel,
  FormControlLabel,
  Grid,
} from '@mui/material'
import { useLocale } from 'containers'
import masterData from 'model'

interface ICheckBox {
  field: ControllerRenderProps
  fieldState: ControllerFieldState
}

const CheckBox: FC<ICheckBox> = ({ field, fieldState }) => {
  const { messages } = useLocale()
  const options = masterData[field.name]

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checkBoxValue = parseInt(event.target.value, 10)
    const fieldValue = field.value as any
    const value = fieldValue.includes(checkBoxValue)
      ? fieldValue.filter((v: any) => v !== checkBoxValue)
      : [...fieldValue, checkBoxValue]

    field.onChange(value)
  }

  return options ? (
    <FormControl component='fieldset' variant='standard'>
      <FormLabel component='legend'>
        {messages[`offer-form.${field.name}`]}
      </FormLabel>
      <FormGroup>
        <Grid container spacing={3} wrap='wrap'>
          {options.map((option) => (
            <Grid item key={option.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={handleChange}
                    name={option.label}
                    value={option.value}
                  />
                }
                label={messages[option.label]}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>
  ) : (
    <FormControl component='fieldset' error={!!fieldState.error}>
      <FormControlLabel
        value={field.value}
        control={<Checkbox checked={field.value} onChange={field.onChange} />}
        label={messages[`offer-form.${field.name}`]}
        labelPlacement='end'
      />
      {!!fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  )
}

export default CheckBox
