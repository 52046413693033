import { FC } from 'react'
import Jumbotron from 'components/Jumbotron'
import Layout from 'components/Layout'
import OffersList from 'components/OffersList'
import HomeContent from 'components/HomeContent'
import JumbotronContent from 'components/JumbotronContent'
const Home: FC = () => {
  return (
    <Layout>
      <Jumbotron>
        <JumbotronContent />
      </Jumbotron>
      <HomeContent />
      <OffersList />
    </Layout>
  )
}
export default Home
