import { makeStyles } from '@mui/styles'
import Hero from 'assets/hero.png'

const useStyles = makeStyles(() => ({
  heroImage: {
    backgroundImage: `url(${Hero})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '60vh',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
}))

export default useStyles
