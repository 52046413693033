import { FC, useState, useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  Paper,
  TextField,
  Grid,
  Typography,
  Chip,
} from '@mui/material'
import { useRequest } from 'helpers'
import { useAuth, useAlert, useLocale } from 'containers'
import LoadingButton from '@mui/lab/LoadingButton'
import { Types } from 'model'
import { useQueryParams } from 'helpers/useQueryParams'
import ContactsList from 'components/ContactsList'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'

const OfferMessages: FC = () => {
  const [message, setMessage] = useState<string>('')
  const [contactMessages, setContactMessages] = useState<Types.MessageType[]>(
    []
  )

  const params = useQueryParams()
  const offerId = params.getOne('offerId')
  const contactId = params.getOne('contactId')
  const [offerMessage, setOfferMessage] = useState<Types.MessageType[]>([])

  const { request } = useRequest()
  const { userId, userRole } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const { setAlert } = useAlert()
  const { messages } = useLocale()
  const navigate = useNavigate()

  const contacts = useMemo(
    () =>
      offerMessage
        .sort((a, b) => {
          if (a.createDate > b.createDate) {
            return -1
          }
          if (a.createDate < b.createDate) {
            return 1
          }
          return 0
        })
        .reduce((acc, { issuer, recipient }) => {
          if (userRole === Types.UserRole.Provider) {
            const contact =
              issuer.role === Types.UserRole.Seeker ? issuer.id : recipient.id
            return acc.includes(contact) ? acc : [...acc, contact]
          } else {
            const contact =
              issuer.role === Types.UserRole.Provider ? issuer.id : recipient.id

            return acc.includes(contact) ? acc : [...acc, contact]
          }
        }, [] as string[]),
    [offerMessage, userRole]
  )
//TODO is this needed twice?
  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const data = await request(`contacts/${offerId}`, 'get')
        const { messages } = await data.json()
        setOfferMessage(messages)
      } catch (error) {
        console.log(error)
      }
    }
    fetchOfferDetails()
  }, [offerId])

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const data = await request(`contacts/${offerId}`, 'get')

        const { messages }: { messages: Types.MessageType[] } =
          await data.json()

        const contactMessage = messages
          .filter(({ issuer, recipient }) => {
            if (userRole === Types.UserRole.Provider) {
              const contact =
                issuer.role === Types.UserRole.Seeker ? issuer.id : recipient.id

              return contact === contactId
            } else {
              const contact =
                issuer.role === Types.UserRole.Provider
                  ? issuer.id
                  : recipient.id

              return contact === contactId
            }
          })
          .sort((a: Types.MessageType, b: Types.MessageType) => {
            if (a.createDate > b.createDate) {
              return 1
            }
            if (a.createDate < b.createDate) {
              return -1
            }
            return 0
          })

        setContactMessages(contactMessage)
      } catch (error) {
        console.log(error)
      }
    }
    fetchOfferDetails()
  }, [contactId, offerId, userRole])

  const handleSend = async () => {
    try {
      setLoading(true)
      const res = await request('contacts', 'post', {
        offerId,
        message,
        relatedMessageId: contactMessages[contactMessages.length - 1].id,
      })

      const newMessage = await res.json()
      setContactMessages([...contactMessages, newMessage])

      if (res.ok) {
        setAlert({
          type: 'success',
          message: messages['offer-messages.alert.success'],
        })
      } else {
        setAlert({
          type: 'error',
          message: messages['header.alert.error'],
        })
      }
      setMessage('')
      setLoading(false)
    } catch (error) {
      setAlert({
        type: 'error',
        message: messages['header.alert.error'],
      })
      setLoading(false)
    }
  }

  return (
    <Box
      component={'section'}
      sx={{
        maxHeight: '100%',
        padding: '2rem',
        maxWidth: '2000px',
        margin: 'auto',
        marginTop: '20px',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography
            variant={'h6'}
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {messages['contact-list.button.contacts']}
          </Typography>
          <ContactsList
            contacts={contacts}
            offerId={offerId}
            selected={contactId}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {contactMessages.map((m: Types.MessageType) => {
            const { message, issuer, recipient, id, createDate } = m

            return (
              <Paper
                key={id}
                elevation={3}
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  marginRight: userId === issuer.id ? 'auto' : 2,
                  marginLeft: userId === recipient.id ? 'auto' : 2,
                  padding: 2,
                  width: '90%',
                  backgroundColor:
                    userId === issuer.id ? 'primary.main' : 'secondary.',
                }}
              >
                <Typography
                  variant={'body1'}
                  color='textPrimary'
                  style={{ marginBottom: '10px', marginTop: '10px' }}
                >
                  {message}
                </Typography>
                <div style={{ textAlign: 'end' }}>
                  <Chip
                    label={format(new Date(createDate), 'yyyy.MM.dd-HH:mm')}
                  />
                </div>
              </Paper>
            )
          })}

          <TextField
            sx={{
              marginTop: 2,
              marginBottom: 2,
              padding: 2,
            }}
            value={message}
            fullWidth
            placeholder='Nachricht'
            variant='outlined'
            multiline
            rows={5}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <LoadingButton
              onClick={handleSend}
              variant='contained'
              disabled={!message}
              loading={loading}
            >
              {messages['offer-message.send']}
            </LoadingButton>
            <Button
              onClick={() => navigate(`/offer-details?offerId=${offerId}`)}
              variant='outlined'
              color='warning'
            >
              {messages['offer-message.backToOffer']}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OfferMessages
