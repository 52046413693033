import { FC } from 'react'
import { ControllerRenderProps, ControllerFieldState } from 'react-hook-form'
import TextField from 'components/TextField'
import Select from 'components/Select'
import DatePicker from 'components/DatePicker'
import CheckBox from 'components/CheckBox'

interface IFieldControlProps {
  field: ControllerRenderProps
  fieldState: ControllerFieldState
  type?: string
  minDate?: string | Date | null
  maxDate?: string | Date | null
}

const FieldControl: FC<IFieldControlProps> = ({
  type,
  field,
  fieldState,
  minDate,
  maxDate,
}) => {
  switch (type) {
    case 'select':
      return <Select field={field} fieldState={fieldState} />
    case 'date':
      return (
        <DatePicker
          field={field}
          fieldState={fieldState}
          minDate={minDate}
          maxDate={maxDate}
        />
      )
    case 'checkBox':
      return <CheckBox field={field} fieldState={fieldState} />
    default:
      return <TextField type={type} field={field} fieldState={fieldState} />
  }
}

export default FieldControl
