import { useAuth } from 'containers'

const baseUrl = process.env.REACT_APP_BACKEND_HOST

export const useRequest = () => {
  const { token } = useAuth()

  const request = async (
    path: string,
    method: 'get' | 'post' | 'put' | 'delete',
    data?: any
  ) =>
    await fetch(`${baseUrl}/${path}`, {
      method,
      cache: 'no-cache',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      body: data && JSON.stringify(data),
    })

  return { request }
}
