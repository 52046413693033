import { FC } from 'react'
import OffersList from 'components/OffersList'
import Layout from 'components/Layout'
import { Box, Typography } from '@mui/material'
import { useLocale } from 'containers'
import { Outlet } from 'react-router-dom'

const Offers: FC = () => {
  const { messages } = useLocale()

  return (
    <Layout>
      <Box
        sx={{
          maxHeight: '100%',
          padding: '2rem',
          maxWidth: '2000px',
          margin: 'auto',
          marginTop: '80px',
        }}
      >
        <Typography variant='h4'>{messages['offers.heading']}</Typography>
        <ul>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['offers-page.step1']}
            </Typography>
          </li>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['offers-page.step2']}
            </Typography>
          </li>
        </ul>
      </Box>
      <OffersList />
      <Outlet />
    </Layout>
  )
}

export default Offers
