import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useLocale } from 'containers'

function IntroOffering() {
  const { messages } = useLocale()
  const subDomain = window.location.hostname.split('.')[0]

  return (
    <Grid container direction='row' justifyContent='center'>
      <Grid item xs={11}>
        {subDomain === 'bodenseekreis' ? (
          <>
            <Typography
              variant={'h4'}
              color='textPrimary'
              style={{ marginBottom: '20px', marginTop: '20px' }}
            >
              {messages['landing-page.intro.heading']}
            </Typography>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['landing-page.intro.p0.bodenseekreis']}
            </Typography>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['landing-page.intro.p1.bodenseekreis']}
            </Typography>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['landing-page.intro.p2.bodenseekreis']}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant={'h4'}
              color='textPrimary'
              style={{ marginBottom: '20px', marginTop: '20px' }}
            >
              {messages['landing-page.intro.heading']}
            </Typography>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['landing-page.intro.p0']}
            </Typography>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['landing-page.intro.p1']}
            </Typography>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['landing-page.intro.p2']}
            </Typography>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['landing-page.intro.p3']}
            </Typography>
          </>
        )}
        <Typography
          variant={'h4'}
          color='textPrimary'
          style={{ marginBottom: '10px', marginTop: '10px' }}
        >
          {messages['intro-offering.howto.heading']}
        </Typography>
        <ul>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['intro-offering.howto.step1']}
            </Typography>
          </li>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['intro-offering.howto.step2']}
            </Typography>
          </li>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['intro-offering.howto.step3']}
            </Typography>
          </li>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['intro-offering.howto.step4']}
            </Typography>
          </li>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['intro-offering.howto.step5']}
            </Typography>
          </li>
          <li>
            <Typography color='textPrimary' style={{ marginTop: '10px' }}>
              {messages['intro-offering.howto.step6']}
            </Typography>
          </li>
        </ul>
      </Grid>
    </Grid>
  )
}

export default IntroOffering
