import { FC } from 'react'
import { ControllerRenderProps, ControllerFieldState } from 'react-hook-form'
import {
  Select as MuiSelect,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@mui/material'
import { useLocale } from 'containers'
import masterData, { Types } from 'model'

interface ISelect {
  field: ControllerRenderProps
  fieldState: ControllerFieldState
}

const Select: FC<ISelect> = ({ field, fieldState }) => {
  const { messages } = useLocale()
  const options = masterData[field.name]
  return (
    <FormControl fullWidth error={!!fieldState.error}>
      <InputLabel id={`${field.name}-label`}>
        {messages[`offer-form.${field.name}`]}
      </InputLabel>
      <MuiSelect
        labelId={`${field.name}-label`}
        id={field.name}
        value={field.value}
        label={messages[`offer-form.${field.name}`]}
        onChange={field.onChange}
      >
        {options.map((option: Types.SelectOptionType) => (
          <MenuItem key={option.value} value={option.value}>
            {messages[option.label]}
          </MenuItem>
        ))}
      </MuiSelect>
      {!!fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  )
}

export default Select
