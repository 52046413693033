export enum UserRole {
  'Provider' = 0,
  'Seeker' = 1,
}
export enum OfferStatus {
  'Active' = 0,
  'InActive' = 1,
}

export type SelectOptionType = {
  label: string
  value: string | number
}

export type OfferType = {
  id: string
  type: number
  numberOfBeds?: number
  numberOfRooms?: number
  startingDate: string | null
  endDate?: string | null
  annotations?: string
  zip: string
  city: string
  street?: string
  houseNumber?: number
  floor?: number
  livingArea?: number
  offerEndDate?: string | null
  email: string
  phoneNumber?: string
  equipment?: number[]
  termsAgreed: boolean
  messagesCount: number
  status: OfferStatus
}

export type MessageContact = {
  id: string
  role: UserRole
}

export type MessageType = {
  id: string
  createDate: string
  issuer: MessageContact
  recipient: MessageContact
  message: string
}
