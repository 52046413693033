import { FC, useMemo } from 'react'
import { useOffers } from 'containers'
import { Box, Grid } from '@mui/material'
import OfferCard from 'components/OfferCard'
import { useLocale } from 'containers'
import OffersFilter from 'components/OffersFilter'
import { useQueryParams } from 'helpers'

const OffersList: FC = () => {
  const subDomain = window.location.hostname.split('.')[0]
  // const subDomain = 'bodenseekreis'
  const { offers } = useOffers()
  const { messages } = useLocale()
  const { index } = useQueryParams()

  const displayedOffers = useMemo(
    () =>
      (offers as any).filter((offer: any) =>
        Object.keys(index).every((k: any) =>
          index[k].includes(offer[k]?.toString() as any)
        )
      ),
    [offers, index]
  )
  const bodenSeeOffers = displayedOffers.filter((o: any) => {
    return (
      o.zip.startsWith('880') ||
      o.zip.startsWith('783') ||
      o.zip.startsWith('886') ||
      o.zip.startsWith('887')
    )
  })

  return (
    <Box
      component={'section'}
      sx={{ padding: '2rem', maxWidth: '2000px', margin: 'auto' }}
    >
      <OffersFilter />
      {displayedOffers.length ? (
        subDomain.toLowerCase() === 'bodenseekreis' ? (
          <Grid container spacing={2}>
            {bodenSeeOffers.map((o: any) => (
              <Grid item xs={12} lg={12} xl={12} key={o.id}>
                <OfferCard offer={o} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {displayedOffers.map((o: any) => (
              <Grid item xs={12} lg={12} xl={12} key={o.id}>
                <OfferCard offer={o} />
              </Grid>
            ))}
          </Grid>
        )
      ) : (
        <>{messages['offer-list.noOffers']}</>
      )}
    </Box>
  )
}

export default OffersList
