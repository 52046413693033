import { FC, useState, MouseEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useLocale } from 'containers'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
const HeaderMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const { messages } = useLocale()
  const navigate = useNavigate()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    window.sessionStorage.removeItem('token')
    navigate('/')
    window.location.reload()
  }

  return (
    <>
      <IconButton
        aria-label='more'
        id='long-button'
        // aria-controls={open ? 'long-menu' : undefined}
        // aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClickListItem}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id='lock-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <MenuItem onClick={() => navigate('/user-offers')}>
          {messages['header.button.offers']}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          {messages['header.button.logout']}
        </MenuItem>
      </Menu>
    </>
  )
}

export default HeaderMenu
