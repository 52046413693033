import { FC, useState, useEffect, useMemo } from 'react'
import { useRequest } from 'helpers'
import OfferCard from 'components/OfferCard'
import ContactsList from 'components/ContactsList'
import {
  Grid,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from '@mui/material'
import { Types } from 'model'
import { useAuth, useAlert, useLocale } from 'containers'
import { useQueryParams } from 'helpers/useQueryParams'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import OfferForm from 'components/OfferForm'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate } from 'react-router-dom'

const OfferDetails: FC = () => {
  const { request } = useRequest()
  const { messages } = useLocale()
  const params = useQueryParams()
  const offerId = params.getOne('offerId')
  const { userRole } = useAuth()
  const [offerMessage, setOfferMessage] = useState<Types.MessageType[]>([])
  const [offer, setOffer] = useState<any>(null)

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { setAlert } = useAlert()
  const navigate = useNavigate()

  const [confirmation, setConfirmation] = useState<any>({
    open: false,
  })

  const contacts = useMemo(
    () =>
      offerMessage
        .sort((a:any, b:any)=>b.createDate - a.createDate)
        .reduce((acc, { issuer, recipient, createDate }) => {
          if (userRole === Types.UserRole.Provider) {
            const contact =
              issuer.role === Types.UserRole.Seeker ? issuer.id : recipient.id

            return acc.includes(contact) ? acc: [...acc, contact]
          } else {
            const contact =
              issuer.role === Types.UserRole.Provider ? issuer.id : recipient.id

            return acc.includes(contact) ? acc : [...acc, contact]
          }
      }, [] as string[]),
    [offerMessage, userRole]
  )

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const data = await request(`contacts/${offerId}`, 'get')
        const { messages, offer } = await data.json()
        setOfferMessage(messages)
        setOffer(offer)
      } catch (error) {
        console.log(error)
      }
    }
    fetchOfferDetails()
  }, [offerId])

  const changeOfferStatus = async () => {
    setLoading(true)
    try {
      const res = await request('offers', 'put', {
        ...offer,
        status:
          offer?.status === Types.OfferStatus.Active
            ? Types.OfferStatus.InActive
            : Types.OfferStatus.Active,
      })
      if (res.ok) {
        const updatedOffer = await res.json()
        setOffer(updatedOffer)
        setAlert({
          type: 'success',
          message: messages['offer.update.message'],
        })
      } else {
        setAlert({
          type: 'error',
          message: messages['header.alert.error'],
        })
      }
      setConfirmation({ ...confirmation, open: false })
      setLoading(false)
    } catch (error) {
      setAlert({
        type: 'error',
        message: messages['header.alert.error'],
      })
      setLoading(false)
    }
  }

  const deleteOffer = async () => {
    setLoading(true)
    try {
      const res = await request(`offers/${offerId}`, 'delete')
      if (res.ok) {
        setAlert({
          type: 'success',
          message: messages['offer.delete.message'],
        })
      } else {
        setAlert({
          type: 'error',
          message: messages['header.alert.error'],
        })
      }
      setConfirmation({ ...confirmation, open: false })
      setLoading(false)
      navigate('/user-offers')
    } catch (error) {
      setAlert({
        type: 'error',
        message: messages['header.alert.error'],
      })
      setLoading(false)
    }
  }

  return (
    <Box
      component={'section'}
      sx={{
        maxHeight: '100%',
        padding: '2rem',
        maxWidth: '2000px',
        margin: 'auto',
        marginTop: '20px',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Typography
            variant={'h6'}
            color='textPrimary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {messages['contact-list.button.contacts']}
          </Typography>
          <ContactsList contacts={contacts} offerId={offerId} />
        </Grid>
        <Grid item xs={12} md={9}>
          {offer && (
            <>
              <OfferCard offer={offer} />
              {userRole === Types.UserRole.Provider && (
                <Box
                  component={'section'}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '1rem',
                  }}
                >
                  <div>
                    <Button
                      variant='contained'
                      endIcon={<EditIcon />}
                      color={'info'}
                      onClick={() => setOpen(true)}
                      sx={{
                        marginRight: '1rem',
                      }}
                    >
                      {messages['offer.update.button.edit']}
                    </Button>
                    <Button
                      variant='contained'
                      onClick={() =>
                        setConfirmation({
                          open: true,
                          type: 'toggleStatus',
                          message: messages['offer.update.statusChange'],
                        })
                      }
                      endIcon={
                        offer.status === Types.OfferStatus.Active ? (
                          <NotificationsOffIcon />
                        ) : (
                          <NotificationsIcon />
                        )
                      }
                      color={
                        offer.status === Types.OfferStatus.Active
                          ? 'warning'
                          : 'success'
                      }
                    >
                      {offer.status === Types.OfferStatus.Active
                        ? messages['offer.update.button.deactivate']
                        : messages['offer.update.button.activate']}
                    </Button>
                  </div>
                  <Button
                    variant='contained'
                    endIcon={<DeleteIcon />}
                    color={'error'}
                    onClick={() =>
                      setConfirmation({
                        message: messages['offer.update.button.delete.text'],
                        open: true,
                        type: 'delete',
                      })
                    }
                  >
                    {messages['offer.update.button.delete.text']}
                  </Button>
                </Box>
              )}
            </>
          )}

          {/* {!!contacts.length && offer && contactId && (
            <OfferMessages
              contactId={contactId}
              offerId={offer.id}
              onContactSelect={() => navigate(pathname + search)}
            />
          )} */}
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll='body'
        maxWidth={'xl'}
        fullWidth
      >
        <DialogContent>
          <OfferForm offer={offer} onClose={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmation.open}
        onClose={() => setConfirmation({ ...confirmation, open: false })}
        maxWidth={'md'}
        fullWidth
      >
        <DialogTitle>{messages['offer.update.button.update.text']}</DialogTitle>
        <DialogContent dividers>{confirmation.message}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmation({ ...confirmation, open: false })}
            variant={'contained'}
          >
            {messages['offer.update.button.cancel']}
          </Button>
          <LoadingButton
            onClick={() => {
              return confirmation.type === 'toggleStatus'
                ? changeOfferStatus()
                : deleteOffer()
            }}
            loading={loading}
            variant={'contained'}
            color={
              confirmation.type === 'delete'
                ? 'error'
                : offer?.status === Types.OfferStatus.Active
                ? 'success'
                : 'warning'
            }
          >
            {
              // can replace delete and update with messages ?? already replaced up
              confirmation.type === 'delete'
                ? messages['offer.update.button.delete']
                : messages['offer.update.button.update']
            }
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default OfferDetails
