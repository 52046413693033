import { FC } from 'react'
import { ControllerRenderProps, ControllerFieldState } from 'react-hook-form'
import { TextField as MuiTextField } from '@mui/material'
import { useLocale } from 'containers'

interface ITextField {
  field: ControllerRenderProps
  fieldState: ControllerFieldState
  type?: string
}

const TextField: FC<ITextField> = ({ field, fieldState, type }) => {
  const { messages } = useLocale()
  return (
    <MuiTextField
      onChange={field.onChange}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      value={field.value}
      fullWidth
      label={messages[`offer-form.${field.name}`]}
      variant='outlined'
      color='primary'
      type={type}
      multiline={type === 'textArea'}
      rows={5}
    />
  )
}

export default TextField
