import { FC, createContext, useContext, useState, useCallback } from 'react'
import jwt_decode from 'jwt-decode'
import { fromUnixTime, differenceInMinutes, addHours } from 'date-fns'
import { Types } from 'model'
interface IAuthContext {
  token: string
  email: string
  userId: string
  isValid: boolean
  userRole: Types.UserRole
  setToken(token: string | undefined): void
}

const AuthContext = createContext<IAuthContext>({
  token: '',
  email: '',
  userId: '',
  isValid: false,
  userRole: Types.UserRole.Seeker,
  setToken: (token: string | undefined) => {},
})

const useAuth = () => useContext(AuthContext)

interface IAuthProviderProps {
  initialValue: any
}

const AuthProvider: FC<IAuthProviderProps> = ({ children, initialValue }) => {
  const [accessToken, setAccessToken] = useState(initialValue)
  const decoded = accessToken && (jwt_decode(accessToken) as any)

  const email = decoded?.email ?? ''
  const userId = decoded?.sub ?? ''
  const expiryTime = decoded?.exp ?? 0
  const userRole = decoded?.role

  const setToken = useCallback((token) => {
    setAccessToken(token)
    window.sessionStorage.setItem('token', token)
  }, [])

  const isValid = expiryTime
    ? differenceInMinutes(
        addHours(new Date(fromUnixTime(expiryTime)), 2),
        new Date(fromUnixTime(expiryTime))
      ) > 0
    : false

  return (
    <AuthContext.Provider
      value={{
        token: accessToken,
        email,
        userId,
        isValid,
        userRole,
        setToken: setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuth }
