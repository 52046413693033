import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FieldControl from './FieldControl'

interface IFieldProps {
  name: string
  type?: string
  rules?: any
  minDate?: string | Date | null
  maxDate?: string | Date | null
}

const Field: FC<IFieldProps> = ({ name, type, rules, minDate, maxDate }) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FieldControl
          field={field}
          fieldState={fieldState}
          type={type}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    />
  )
}

export default Field
