import { FC, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Button,
  CardActionArea,
} from '@mui/material'
import { Types } from 'model'
import { useLocale, useAuth } from 'containers'
import ContactForm from 'components/ContactForm'
import { useNavigate } from 'react-router-dom'
import OfferContent from 'components/OfferCard/OfferContent'
import { useLocation } from 'react-router-dom'

interface ICardOffer {
  offer: Types.OfferType
  clickAble?: boolean
}

const OfferCard: FC<ICardOffer> = ({ offer }) => {
  const { messages } = useLocale()
  const [interested, setInterested] = useState<boolean>(false)
  const { userId, userRole } = useAuth()
  const { pathname } = useLocation()
  const isRootPath = pathname === '/'
  const isUserOffersPath = pathname.includes('user-offers')
  const navigate = useNavigate()

  return (
    <Card
      raised
      sx={{
        display: 'flex',
        flexDirection: 'column',
        msOverflowX: 'hidden',
        justifyContent: 'space-between',
      }}
    >
      {isUserOffersPath ? (
        <CardActionArea
          onClick={() => navigate(`/offer-details?offerId=${offer.id}`)}
        >
          <CardContent>
            <OfferContent offer={offer} />
          </CardContent>
        </CardActionArea>
      ) : (
        <CardContent>
          <OfferContent offer={offer} />
        </CardContent>
      )}

      {(!userId || userRole === Types.UserRole.Seeker) && isRootPath && (
        <CardActions>
          {!interested && (
            <Button
              onClick={() => {
                setInterested(true)
              }}
              variant='contained'
              size='large'
              sx={{ margin: '5px' }}
            >
              {messages['offer-card.button.interested']}
            </Button>
          )}
          {interested && (
            <ContactForm
              offerId={offer.id}
              onClose={() => setInterested(false)}
            />
          )}
        </CardActions>
      )}
    </Card>
  )
}

export default OfferCard
