import { Types } from 'model'
var today = new Date()
var date =
  today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear()
export const defaultValues: Omit<Types.OfferType, 'id' | 'messagesCount'> = {
  type: 0,
  numberOfBeds: 1,
  numberOfRooms: 0,
  startingDate: date,
  endDate: null,
  annotations: '',
  zip: '',
  city: '',
  street: '',
  floor: 0,
  livingArea: 0,
  offerEndDate: null,
  email: '',
  phoneNumber: '',
  equipment: [],
  termsAgreed: false,
  status: Types.OfferStatus.Active,
}
