import { FC, useCallback } from 'react'
import { Grid, FormControl, InputLabel, MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useLocale, useOffers } from 'containers'
import { useQueryParams } from 'helpers'
import { Types } from 'model'

type FilterType = {
  label: string
  value: keyof Types.OfferType
}

const OffersFilter: FC = () => {
  const subDomain = window.location.hostname.split('.')[0]
  const { offers } = useOffers()
  const { messages } = useLocale()
  const { params, getOne, commit } = useQueryParams()
  const getOptions = useCallback(
    (prop: keyof Types.OfferType) =>
      offers
        .filter((o) => {
          if (subDomain.toLowerCase() === 'bodenseekreis') {
            return (
              o.zip.startsWith('880') ||
              o.zip.startsWith('783') ||
              o.zip.startsWith('886') ||
              o.zip.startsWith('887')
            )
          }
          return true
        })
        .reduce(
          (acc, offer) =>
            acc.includes(offer[prop]) ? acc : [...acc, offer[prop]],
          [] as any
        )
        .sort((a: any, b: any) => {
          if (a > b) return 1
          if (a < b) return -1
          return 0
        })
        .filter((o: any) => o !== null)
        .map((o: any) => ({ label: o, value: o.toString() })),
    [offers, subDomain]
  )

  const onChange = useCallback(
    (key: string, $default: any = 'all') => {
      return (event: SelectChangeEvent) => {
        const value = event.target.value
        console.log(event, value)

        if (value === $default) {
          params.delete(key)
        } else if (Array.isArray(value)) {
          params.delete(key)
          value.forEach((v) => params.append(key, v))
        } else {
          params.set(key, value)
        }
        commit(params)
      }
    },
    [commit, params]
  )

  const filters: FilterType[] = [
    { label: messages['offer-form.zip'], value: 'zip' },
    { label: messages['offer-form.numberOfRooms'], value: 'numberOfRooms' },
    { label: messages['offer-form.numberOfBeds'], value: 'numberOfBeds' },
  ]
  return (
    <Grid container spacing={2} paddingBottom={2}>
      {filters.map((f) => (
        <Grid item key={f.value} xs={12} md={12 / filters.length}>
          <FormControl fullWidth>
            <InputLabel id={`${f.value}-label`}>{f.label}</InputLabel>
            <Select
              labelId={`${f.value}-label`}
              label={f.label}
              id={f.value}
              value={getOne(f.value) || 'all'}
              onChange={onChange(f.value)}
              variant='outlined'
            >
              {[
                { label: messages['offer-form.all'], value: 'all' },
                ...getOptions(f.value),
              ].map((o: any) => (
                <MenuItem value={o.value} key={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ))}
    </Grid>
  )
}

export default OffersFilter
