import { FC, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import Field from 'components/Field'
import { useAlert, useAuth } from 'containers'
import { useLocale } from 'containers'
import { useRequest } from 'helpers'
import LoadingButton from '@mui/lab/LoadingButton'
import { Types } from 'model'
import { defaultValues } from './formConstants'

interface IOfferForm {
  offer?: Types.OfferType
  onClose?: () => void
}

const OfferForm: FC<IOfferForm> = ({ offer, onClose }) => {
  const { messages } = useLocale()
  const { userId } = useAuth()

  const methods = useForm({
    defaultValues: offer ? { ...offer } : { ...defaultValues },
  })
  const { handleSubmit, reset, watch } = methods
  const navigate = useNavigate()

  const { setAlert } = useAlert()
  const { request } = useRequest()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (data: any) => {
    const { email, termsAgreed, ...rest } = data
    setLoading(true)
    try {
      const res = offer
        ? await request('offers', 'put', {
            ...rest,
            status: Types.OfferStatus.Active,
          })
        : await request('auth', 'post', {
            email: !userId ? email : undefined,
            offer: { ...rest, status: Types.OfferStatus.Active },
          })
      if (res.ok) {
        setAlert({
          type: 'success',
          message: offer
            ? messages['offer.update.message']
            : `Wir haben einen Link an ${email} geschickt - bitte klicken Sie auf den Link um Ihr Angebot zu bestätigen, erst danach erscheint es online auf unserer Seite. Vielen Dank für Ihre Unterstützung!`,
        })
        reset()
        if (offer) {
          navigate('/user-offers')
        }
      } else {
        setAlert({
          type: 'error',
          message: messages['header.alert.error'],
        })
      }
      onClose && onClose()
      setLoading(false)
    } catch (error) {
      setAlert({
        type: 'error',
        message: messages['header.alert.error'],
      })
      setLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} paddingY={2}>
          <Grid item sm={6} xs={12}>
            <Field
              name={'type'}
              type={'select'}
              rules={{
                required: messages['offer-form.warning.type'],
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12} />
          <Grid item sm={6} xs={12}>
            <Field 
              name={'numberOfBeds'} 
              type={'number'} 
              rules={{
                required: messages['offer-form.warning.beds'],
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field 
            name={'numberOfRooms'} 
            type={'number'} 
            rules={{
              required: messages['offer-form.warning.rooms'],
            }}
          />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name={'floor'} type={'number'} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name={'livingArea'} type={'number'} />
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingY={2}>
          <Grid item sm={6} xs={12}>
            <Field
              name={'startingDate'}
              type={'date'}
              minDate={new Date()}
              rules={{
                required: messages['offer-form.warning.startDate'],
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name={'endDate'}
              type={'date'}
              minDate={watch('startingDate')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingY={2}>
          <Grid item sm={6} xs={12}>
            <Field
              name={'zip'}
              rules={{
                required: messages['offer-form.warning.zip'],
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name={'city'}
              rules={{
                required: messages['offer-form.warning.city'],
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field name={'street'} />
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingY={2}>
          <Grid item sm={6} xs={12}>
            <Field name={'offerEndDate'} type={'date'} />
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingY={2}>
          <Grid item xs={12}>
            <Field name={'equipment'} type={'checkBox'} />
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingY={2}>
          <Grid item xs={12}>
            <Field name={'annotations'} type={'textArea'} />
          </Grid>
        </Grid>

        <Grid container spacing={2} paddingY={2}>
          {!offer && !userId && (
            <Grid item sm={6} xs={12}>
              <Field
                name={'email'}
                rules={{
                  required: messages['offer-form.warning.email'],
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: messages['offer-form.warning.email.pattern'],
                  },
                }}
              />
            </Grid>
          )}

          <Grid item sm={6} xs={12}>
            <Field name={'phoneNumber'} />
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingY={2}>
          <Grid item sm={6} xs={12}>
            <Field
              name={'termsAgreed'}
              type={'checkBox'}
              rules={{
                required: messages['offer-form.warning.terms'],
              }}
            />
            <br />
            <Link to={'/impressum'} target='_blank'>
              {messages['offer-form.terms.imprint-link']}
            </Link>
          </Grid>
          <Grid item sm={6} xs={12}>
            <LoadingButton variant='contained' type='submit' loading={loading}>
              {messages['offer-form.button.send']}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default OfferForm
