import { FC, createContext, useContext, useState, useMemo } from 'react'

export type AlertTypes = 'success' | 'error' | 'warning' | 'info' | undefined

export interface IAlert {
  type: AlertTypes
  message: string
}

interface IAlertContext {
  alert?: IAlert
  setAlert(alert?: IAlert): void
}

const AlertContext = createContext<IAlertContext>({
  setAlert: (alert: IAlert) => {},
})

const useAlert = () => useContext(AlertContext)

interface IAlertProviderProps {
  initialValue: IAlert | undefined
}

const AlertProvider: FC<IAlertProviderProps> = ({ children, initialValue }) => {
  const [activeAlert, setActiveAlert] = useState<IAlert | undefined>(
    initialValue
  )
  const value = useMemo(
    () => ({
      setAlert: (alert: IAlert) => setActiveAlert(alert),
      alert: activeAlert,
    }),
    [activeAlert]
  )

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

export { AlertProvider, useAlert }
