import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocale } from 'containers'
import useStyles from './styles'
import { Box, Button, Typography } from '@mui/material'
import HouseIcon from '@mui/icons-material/House'

const JumbotronContent: FC = () => {
  const subDomain = window.location.hostname.split('.')[0].toLowerCase()
  // const subDomain = 'bodenseekreis'
  const { messages } = useLocale()
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <Box component='div' className={classes.heroBox}>
      {subDomain === 'bodenseekreis' ? (
        <>
          <Typography variant={'h1'} color={'secondary'}>
            {messages['landing-page.hero.text.bodensee']}
          </Typography>
          <Typography
            color={'secondary'}
            className={classes.heroText}
            variant={'h6'}
            sx={{ fontWeight: '700' }}
          >
            {messages['landing-page.hero.subText.bodensee']}
          </Typography>
        </>
      ) : (
        <Typography
          variant={'h1'}
          color={'secondary'}
          className={classes.heroText}
        >
          {messages['landing-page.hero.text']}
        </Typography>
      )}
      <Button
        variant='outlined'
        size='large'
        startIcon={<HouseIcon />}
        onClick={() => navigate('/offer')}
        color='secondary'
        sx={{
          borderWidth: '3px',
          fontWeight: 'bold',
          ':hover': {
            borderWidth: '3px',
          },
        }}
      >
        <Typography fontWeight={900}>
          {subDomain === 'bodenseekreis'
            ? messages['landing-page.button.offer.bodenseekreis']
            : messages['landing-page.button.offer']}
        </Typography>
      </Button>
    </Box>
  )
}

export default JumbotronContent
