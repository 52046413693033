import { FC } from 'react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import { useLocale } from 'containers'

const Footer: FC = () => {
  const { messages } = useLocale()
  const subDomain = window.location.hostname.split('.')[0]
  return (
    <Grid
      spacing={0}
      container
      direction='row'
      justifyContent='center'
      style={{ marginTop: '80px', backgroundColor: '#ffffff' }}
      component='footer'
    >
      <Grid item sm={5}></Grid>
      <Grid item sm={3}>
        <Typography
          paragraph
          color='textSecondary'
          style={{ marginTop: '20px' }}
        ></Typography>
      </Grid>
      <Grid item sm={4}>
        <Typography
          color='textPrimary'
          style={{
            textAlign: 'right',
            paddingRight: '50px',
            marginTop: '20px',
          }}
        >
          <span style={{ margin: '20px' }}>
            {subDomain.toLowerCase() === 'bodenseekreis' ? (
              <a href='mailto:ukraine-ami@bodenseekreis.de'>
                {messages['footer.link.problem']}
              </a>
            ) : (
              <a href='mailto:service@jobkraftwerk.com'>
                {messages['footer.link.problem']}
              </a>
            )}
          </span>
          <span style={{ margin: '20px' }}>
            <Link to='/impressum' color='textSecondary'>
              {messages['footer.link.imprint']}
            </Link>
          </span>
        </Typography>
      </Grid>
    </Grid>
  )
}
export default Footer
