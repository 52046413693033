import { FC } from 'react'
import Messages from 'components/OfferMessages'
import Layout from 'components/Layout'
import { Box, Typography } from '@mui/material'
import { useLocale } from 'containers'

const OfferMessages: FC = () => {
  const { messages } = useLocale()
  return (
    <Layout>
      <Box
        component={'section'}
        sx={{
          maxHeight: '100%',
          padding: '2rem',
          maxWidth: '2000px',
          margin: 'auto',
          marginTop: '80px',
        }}
      >
        <Typography
          variant={'h4'}
          color='textPrimary'
          style={{ marginBottom: '10px', marginTop: '10px' }}
        >
          {messages['offer-details-page.heading']}
        </Typography>
        <ul>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['offer-details-page.step1']}
            </Typography>
          </li>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['offer-details-page.step2']}
            </Typography>
          </li>
          <li>
            <Typography
              color='textPrimary'
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              {messages['offer-details-page.step3']}
            </Typography>
          </li>
        </ul>
        <Messages />
      </Box>
    </Layout>
  )
}

export default OfferMessages
