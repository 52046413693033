import { useEffect, useState } from 'react'
import { useRequest } from 'helpers'
import { Types } from 'model'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'containers'

const useOffers = () => {
  const [offers, setOffers] = useState<Types.OfferType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { pathname } = useLocation()

  const isUserOffersPath = pathname.includes('user-offers')

  const { request } = useRequest()
  const { userRole } = useAuth()

  useEffect(() => {
    const fetchOffers = async () => {
      setLoading(true)
      try {
        const response = await request(
          `query${isUserOffersPath ? '?only-own' : ''}`,
          'get'
        )

        if (response.ok) {
          const data = await response.json()
          setOffers(data)
        }

        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    fetchOffers()
  }, [isUserOffersPath])

  return {
    offers:
      isUserOffersPath && userRole === Types.UserRole.Provider
        ? offers
        : offers.filter((offer) => offer.status === Types.OfferStatus.Active),
    loading,
  }
}

export default useOffers
