import { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAlert, useLocale, useAuth } from 'containers'
import { useRequest } from 'helpers'
import { Link } from 'react-router-dom'

interface IContactForm {
  offerId: string
  onClose: () => void
}

const ContactForm: FC<IContactForm> = ({ offerId, onClose }) => {
  const { handleSubmit, reset, control } = useForm({
    defaultValues: { email: '', phoneNumber: '', message: '', terms: false },
  })

  const { isValid } = useAuth()
  const { messages } = useLocale()
  const { setAlert } = useAlert()
  const { request } = useRequest()
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (data: any) => {
    setLoading(true)
    try {
      const res = await request('contacts', 'post', { offerId, ...data })

      if (res.ok) {
        setAlert({
          type: 'success',
          message: messages['offer-card.alert.success'],
        })
        reset()
        onClose()
      } else {
        setAlert({
          type: 'error',
          message: messages['header.alert.error'],
        })
      }
      setLoading(false)
    } catch (error) {
      setAlert({
        type: 'error',
        message: messages['header.alert.error'],
      })
      setLoading(false)
    }
  }
  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isValid && (
          <Controller
            control={control}
            name='email'
            rules={{
              required: messages['offer-form.warning.email'],
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: messages['offer-form.warning.email.pattern'],
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={fieldState.invalid}
                margin='dense'
                size='small'
                onChange={field.onChange}
                placeholder={messages['offer-form.email']}
                variant='outlined'
                value={field.value}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name='phoneNumber'
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              margin='dense'
              size='small'
              onChange={field.onChange}
              placeholder={messages['offer-form.phoneNumber']}
              variant='outlined'
              value={field.value}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='message'
          rules={{
            required: messages['contact-form.warning.message'],
          }}
          render={({ field, fieldState }) => (
            <TextField
              error={fieldState.invalid}
              margin='dense'
              fullWidth
              size='small'
              onChange={field.onChange}
              placeholder={messages['offer-form.message']}
              variant='outlined'
              value={field.value}
              multiline
              rows={3}
              helperText={fieldState.error?.message}
            />
          )}
        />
        {!isValid && (
          <Box marginBottom={'25px'}>
            <Controller
              control={control}
              name='terms'
              rules={{
                required: messages['offer-form.warning.terms'],
              }}
              render={({ field, fieldState }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        value={field.value}
                        size='small'
                      />
                    }
                    label={messages['offer-form.termsAgreed']}
                  />
                  <FormHelperText>
                    <Typography color={'error'}>
                      {fieldState.error?.message}
                    </Typography>
                  </FormHelperText>
                </>
              )}
            />
            <Link to={'/impressum'} target='_blank'>
              {messages['offer-form.terms.imprint-link']}
            </Link>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <LoadingButton
            variant='contained'
            size='large'
            type='submit'
            loading={loading}
          >
            {messages['offer-card.button.contact']}
          </LoadingButton>
          <IconButton aria-label='delete' color='primary' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </form>
    </Box>
  )
}

export default ContactForm
